<template>
  <div>
    <b-form @submit.prevent="onSubmit" @reset.prevent="onReset" autocomplete="off">
      <div class="accordion" role="tablist">

        <!-- Site Name -->
        <b-card class="mb-2" no-body>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.seo-1 variant="info">Custom Site Name</b-button>
          </b-card-header>
          <b-collapse id="seo-1" accordion="seo" role="tabpanel">
            <b-card-sub-title class="px-4 pt-3">Use this section to set a custom site name, opposed to your default site name.</b-card-sub-title>
            <b-card-body>
              <b-form-group
                description="This name will not affect your website URL. To revert back to your original site name, empty this field."
                :invalid-feedback="invalidFeedback.customSiteName"
                :state="state.customSiteName"
              >
                <b-form-input v-model="seoData.customSiteName.content" :state="state.customSiteName" :placeholder="'Your site name, e.g.,' + seoData.customSiteName.content" trim></b-form-input>
              </b-form-group>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- TITLE & DESCRIPTION -->
        <b-card class="mb-2" no-body>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.seo-2 variant="info">Default Title &amp; Description</b-button>
          </b-card-header>
          <b-collapse id="seo-2" accordion="seo" role="tabpanel">
            <b-card-body>
              <b-form-group
                description="This will show up in search engines as a brief title for your business."
                :invalid-feedback="invalidFeedback.defaultTitle"
                :state="state.defaultTitle"
              >
                <b-form-input v-model="seoData.defaultTitle.content" :state="state.defaultTitle" placeholder="SEO Title - Example: N Zone Sports - The Ultimate in Community Sports" trim></b-form-input>
              </b-form-group>
              <b-form-group
                description="This will show up in search engines as a brief description of your business."
                :invalid-feedback="invalidFeedback.defaultDescription"
                :state="state.defaultDescription"
              >
                <b-form-textarea
                  v-model="seoData.defaultDescription.content"
                  :state="state.defaultDescription"
                  placeholder="Description - Example: Youth sports, adult sports, sport camps and other sporting events bringing our community together through the spirit of the game! Come get N' the Zone!"
                  trim
                ></b-form-textarea>
              </b-form-group>
              <b-form-group
                description="This is the custom title text for your logo."
              >
                <b-form-input v-model="seoData.defaultLogoTitle.content" placeholder="Logo Title - Example: N Zone Sports of *siteName* - Get 'N the Zone!" trim></b-form-input>
              </b-form-group>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- FRONT PAGE TITLES -->
        <b-card class="mb-2" no-body>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.seo-3 variant="info">Front Page Titles</b-button>
          </b-card-header>
          <b-collapse id="seo-3" accordion="seo" role="tabpanel">
            <b-card-body>
              <b-alert variant="info" show>This is where you can update the title tags for the links on your front page.</b-alert>
              <b-row>
                <b-col>
                  <b-form-group
                    id="fieldset-frontPageTitleWho"
                    description="Custom Who We Are Title"
                  >
                    <b-form-input v-model="seoData.frontPageTitleWho.content" placeholder="Your Custom Who We Are Title" trim></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="fieldset-frontPageTitlePrograms"
                    description="Custom Programs Title"
                  >
                    <b-form-input v-model="seoData.frontPageTitlePrograms.content" placeholder="Your Custom Current Programs Title" trim></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="fieldset-frontPageTitleHappening"
                    description="Custom Happening Title"
                  >
                    <b-form-input v-model="seoData.frontPageTitleHappening.content" placeholder="Your Custom Happening Now Title" trim></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="fieldset-frontPageTitleSports"
                    description="Custom Sports Title"
                  >
                    <b-form-input v-model="seoData.frontPageTitleSports.content" placeholder="Your Custom Sports We Play Title" trim></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="fieldset-frontPageTitleSchedules"
                    description="Custom Schedules Title"
                  >
                    <b-form-input v-model="seoData.frontPageTitleSchedules.content" placeholder="Your Custom Schedules Title" trim></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="fieldset-frontPageTitleStandings"
                    description="Custom Standings Title"
                  >
                    <b-form-input v-model="seoData.frontPageTitleStandings.content" placeholder="Your Custom Standings Title" trim></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- SUB HEADER CONTENT -->
        <b-card class="mb-2" no-body>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.seo-4 variant="info">Sub Header Content</b-button>
          </b-card-header>
          <b-collapse id="seo-4" accordion="seo" role="tabpanel">
            <b-card-body>
              <b-alert variant="info" show>Add sub content to the header area. The sub header will only display if this field contains content.</b-alert>
              <b-form-group
                id="fieldset-headerSubContent"
              >
                <b-form-input
                  v-model="seoData.headerSubContent.content"
                  placeholder="Example: We offer youth sports in the following areas: ..."
                  trim
                ></b-form-input>
              </b-form-group>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- FOOTER TAGLINE -->
        <b-card class="mb-2" no-body>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.seo-5 variant="info">Footer Tagline</b-button>
          </b-card-header>
          <b-collapse id="seo-5" accordion="seo" role="tabpanel">
            <b-card-body>
              <b-form-group
                id="fieldset-headerSubContent"
              >
                <b-form-input
                  v-model="seoData.footerTagline.content"
                  placeholder="Example: The ultimate in youth sports."
                  maxlength="45"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- GA -->
        <b-card class="mb-2" no-body>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.seo-6 variant="info">Google Analytics</b-button>
          </b-card-header>
          <b-collapse id="seo-6" accordion="seo" role="tabpanel">
            <b-card-body>
              <b-alert variant="info" show>Add Google Analytics tracking to your pages</b-alert>
              <b-form-group
                id="fieldset-gaid"
              >
                <b-form-input
                  v-model="seoData.gaid.content"
                  placeholder="Example: UA-123456-7"
                  maxlength="20"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- WHERE WE PLAY -->
        <b-card class="mb-2" no-body>
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.seo-8 variant="info">Where We Play</b-button>
          </b-card-header>
          <b-collapse id="seo-8" accordion="seo" role="tabpanel">
            <b-card-body>
              <b-alert variant="info" show>Additional pages, relative to a loation</b-alert>
              <b-card-group class="mb-4" columns>
                <b-card v-if="page && page.id" :title="page.title">
                  <p>{{ page.summary }}</p>
                  <div class="text-right">
                    <span v-if="pages.length === 0"><b-button size="sm" variant="danger" @click="onDeleteWhereWePlay(page.id)">Delete</b-button> | </span>
                    <b-button size="sm" variant="warning" :to="{ name: 'Pages', params: { action: 'edit', slug: page.slug } }">Edit</b-button>
                  </div>
                </b-card>
                <b-card v-for="childPage in pages" :key="childPage.id" :title="childPage.title">
                  <p>{{ childPage.summary }}</p>
                  <div class="text-right">
                    <b-button size="sm" variant="danger" @click="onDeleteWhereWePlay(childPage.id)">Delete</b-button> | 
                    <b-button size="sm" variant="warning" :to="{ name: 'Pages', params: { action: 'edit', slug: childPage.slug } }">Edit</b-button>
                  </div>
                </b-card>
              </b-card-group>
              <div class="text-right">
                <b-button variant="outline-success" :to="{ name: 'Pages', params: { action: 'new', slug: 'local-areas' }}">Add Location Info</b-button>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>

        <div>
          <b-btn variant="danger" type="reset">Reset</b-btn>
          <b-btn class="float-right" variant="success" type="submit">Submit</b-btn>
        </div>
      </div>
    </b-form>
    <hr />
    <!-- TrackingCodeManager -->
    <b-card class="mb-2" no-body>
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.seo-7 variant="info">Tracking Code Manager (Alpha)</b-button>
      </b-card-header>
      <b-collapse id="seo-7" accordion="seo" role="tabpanel">
        <b-card-body>
          <b-alert variant="info" show>Add custom code to your pages</b-alert>
          <TrackingCodeManager />
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TrackingCodeManager from '@/components/TrackingCodeManager.vue'
const DEFAULT_SITE_NAME_MAX_LENGTH = 15
const DEFAULT_TITLE_MAX_LENGTH = 50
const DEFAULT_DESCRIPTION_MAX_LENGTH = 160
const SAVE_TPL = {
  "id": null,
  "business": null,
  "dateCreated": null,
  "dateStart": null,
  "type": "seo",
  "title": null,
  "slug": null,
  "content": null,
  "author": null
}
const DEFAULT_SEO_DATA = {
  customSiteName: { title: 'Custom Site Name', content: '' },
  defaultTitle: { title: 'Default Title', content: '' },
  defaultDescription: { title: 'Default Description', content: '' },
  defaultLogoTitle: { title: 'Logo Title', content: '' },
  frontPageTitleWho: { title: 'Custom Who We Are Title', content: '' },
  frontPageTitlePrograms: { title: 'Custom Current programs Title', content: '' },
  frontPageTitleHappening: { title: 'Custom Happening Now Title', content: '' },
  frontPageTitleSports: { title: 'Custom Sports We Play Title', content: '' },
  frontPageTitleSchedules: { title: 'Custom Schedules Title', content: '' },
  frontPageTitleStandings: { title: 'Custom Standings Title', content: '' },
  headerSubContent: { title: 'Header Sub Content', content: '' },
  footerTagline: { title: 'Footer Tagline', content: '' },
  gaid: { title: 'Google Analytics ID', content: '' }
}

export default {
  name: 'seo',
  components: {
    TrackingCodeManager
  },
  data: () => ({
    content: [],
    seoData: {},
    pages: []
  }),
  computed: {
    ...mapGetters([
      // 'content',
      'page'
    ]),
    state () {
      return {
        customSiteName: this.seoData.customSiteName.content.length <= DEFAULT_SITE_NAME_MAX_LENGTH,
        defaultTitle: this.seoData.defaultTitle.content.length <= DEFAULT_TITLE_MAX_LENGTH,
        defaultDescription: this.seoData.defaultDescription.content.length <= DEFAULT_DESCRIPTION_MAX_LENGTH
      }
    },
    invalidFeedback () {
      return {
        customSiteName: (this.seoData.customSiteName.content.length > DEFAULT_TITLE_MAX_LENGTH) ? 'Enter at most ' + DEFAULT_TITLE_MAX_LENGTH + ' characters' : null,
        defaultTitle: (this.seoData.defaultTitle.content.length > DEFAULT_TITLE_MAX_LENGTH) ? 'Enter at most ' + DEFAULT_TITLE_MAX_LENGTH + ' characters' : null,
        defaultDescription: (this.seoData.defaultDescription.content.length > DEFAULT_DESCRIPTION_MAX_LENGTH) ? 'Enter at most ' + DEFAULT_DESCRIPTION_MAX_LENGTH + ' characters' : null
      }
    }
  },
  methods: {
    ...mapActions([
      'getContent',
      'postContent',
      'putContent',
      'deleteContent',
      // event though they're both the same API endpoint,
      // using separate store modules for separation
      'getPage',
      'getPages',
      'deletePage',
      'resetPage',
      'resetPages'
    ]),
    async onSubmit () {
      console.log('submit')
      for (const k in this.seoData) {
        console.log(k, this.seoData[k].content)
        let current = this.content.find((e) => typeof e !== 'undefined' && e.slug === k)
        if (typeof current !== 'undefined' && this.seoData[k].content) {
          await this.putContent({ id: current.id, content: this.seoData[k].content + '' })
        } else if (typeof current !== 'undefined' && !this.seoData[k].content) {
          await this.deleteContent(current.id)
        } else if (this.seoData[k].content) {
          let data =  {  ...SAVE_TPL, ...this.seoData[k] }
          data.author = this.currentUser['@id']
          data.slug = k
          data.dateCreated = this.$moment().format()
          data.dateStart = data.dateCreated
          data.business = this.currentBusiness['@id']
          // console.log(data)
          await this.postContent(data)
        }
      }

      this.showSuccess({ message: 'The SEO data has been updated.' })
    },
    onReset () {
      
    },
    onDeleteWhereWePlay (id) {
      this.$bvModal.msgBoxConfirm('Delete The Page?')
      .then(async confirm => {
        if (confirm) {
          await this.deletePage(id)
          this.doGetPages()
        }
      })
    },
    async doGetLocalAreasPage () {
      return await this.getPage({
        business: this.currentBusiness.id,
        type: 'page',
        slug: 'local-areas' // @TODO pull from const
      })
    },
    async doGetPages () {
      this.pages = await this.getPages({
        business: this.currentBusiness.id,
        type: 'page',
        parent: this.page['@id'],
        pagination: false,
        archived: false
      })
    }
  },
  created () {
    this.seoData = DEFAULT_SEO_DATA
    this.getContent({
      business: this.currentBusiness.id,
      type: 'seo',
      pagination: false
    }).then(resp => {
      this.content = resp
      for (const k in this.seoData) {
        this.seoData[k].content = (typeof this.content.find((c) => c.slug === k) !== 'undefined') ? this.content.find((c) => c.slug === k).content : ''
      }
    })
  },
  mounted () {
    this.doGetLocalAreasPage()
    .then(() => {
      if (this.page && this.page['@id']) {
        this.doGetPages()
      }
    })
  },
  destroyed () {
    this.resetPages()
  }
}
</script>